import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Auth, AuthProvider, authState, GoogleAuthProvider, OAuthProvider, signInWithPopup, UserCredential } from '@angular/fire/auth';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { LoginRequest } from 'src/app/model/login-request';
import { Organizacion } from 'src/app/model/organizacion';
import { Usuario } from 'src/app/model/usuario';
import { UsuarioPerfiles } from 'src/app/model/usuarioPerfiles';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private auth: Auth = inject(Auth);

  readonly authState$ = authState(this.auth);

  URL_API = environment.API_URL
  cargando$ = new Subject<boolean>()

  constructor(private http: HttpClient, 
              private router: Router,
              private titleService:Title) { 
    
  }

  setFirebaseJwtToken(jwtToken:string){
    localStorage.setItem('jwtToken', jwtToken)
  }

  login(loginRequest:LoginRequest){

    let url = environment.API_URL + '/usuario/login'
    this.cargando$.next(true)
    this.http.post<Usuario>(url, loginRequest).subscribe(data =>{

        if(data!=undefined){
          // console.log(data)          
          
          if(false){
            this.router.navigate(['/update-password'])
          }else{

            if(data.perfilList?.length!>1){
              this.router.navigate(['/seleccion-perfil'])

            }else if(data.perfilList?.length==1){
              data.perfilActual = data.perfilList[0]            
              this.setTitle()              
              this.router.navigate(['/app/inicio'])
            }  
            localStorage.setItem('usuario', JSON.stringify(data))
          }
                      
        }

        this.cargando$.next(false)

    })
  }

  setTitle(){
    let organizacion = this.getOrganizacion()
    if(organizacion!=undefined)
      this.titleService.setTitle(organizacion.nombre!)
  }

  loginPostSeleccionPerfil(usuario: Usuario, up:UsuarioPerfiles){
    usuario.perfilActual = up
    localStorage.setItem('usuario', JSON.stringify(usuario)) 
    
    this.setTitle()
    this.router.navigate(['/app/inicio'])    
  }

  signInWithGoogleProvider(): Promise<UserCredential> {
    const provider = new GoogleAuthProvider();

    return this.callPopUp(provider);
  }

  signInWithAppleProvider(): Promise<UserCredential> {
    const provider = new OAuthProvider('apple.com');

    return this.callPopUp(provider);
  }

  async callPopUp(provider: AuthProvider): Promise<UserCredential> {
    try {
      const result = await signInWithPopup(this.auth, provider);

      return result;
    } catch (error: any) {
      return error;
    }
  }


  getUsuario() : Usuario{
    let json = localStorage.getItem("usuario");

    if(json!=undefined)
      return JSON.parse(json) as Usuario
    else return {}

  }  

  getPerfilActual() : UsuarioPerfiles{
    return this.getUsuario().perfilActual!
}

  getOrganizacionId() : any{
      return this.getUsuario().perfilActual?.organizacionId
  }

  getOrganizacion() : Organizacion {
    return this.getUsuario()!.perfilActual?.organizacion!   
  }  

  getUsuariosByOrganizacionId() : Observable<Usuario[]>{
    let url = environment.API_URL + "/usuario/organizacion/"+this.getOrganizacionId()
    return this.http.get<Usuario[]>(url)
  }

}
