<div class="layout-topbar">
    <div class=" flex align-items-center justify-content-center
        p-d-flex p-jc-center p-ai-center layout-topbar-logo"
         style="background: linear-gradient(90deg, #512BD9, #9F88F2);">
        <img src="assets/img/bixper-logo-2.png" alt="logo">
    </div>

    <button #menubutton class="p-link layout-menu-button layout-topbar-button" (click)="layoutService.onMenuToggle()">
        <i class="pi pi-bars"></i>
    </button>

    <button #topbarmenubutton class="p-link layout-topbar-menu-button layout-topbar-button" (click)="layoutService.showProfileSidebar()">
        <i class="pi pi-ellipsis-v"></i>
    </button>

    <div class="flex col-5 -ml-7 layout-topbar-info-user">
        <div class="flex items-center align-items-center justify-center">
            <div class="">
                <p-avatar icon="fa-solid fa-user" size="large" shape="circle"></p-avatar>
            </div>
            <div class="-ml-5">
                <ul class="list-none">
                    <li class="text-sm">
                        <span>{{ usuario.perfilActual?.nombrePila }} {{usuario.perfilActual?.apellidoPaterno}}</span>
                    </li>
                    <li class="text-xs text-400 mt-2">
                        {{ usuario.perfilActual?.organizacion?.nombre }}
                    </li>
                    <li class="text-xs text-400 mt-0 cursor-pointer hover:text-800" *ngIf="isTienePerfiles" (click)="cambiarPerfilClick()">
                        Cambiar Perfil
                    </li>                    
                </ul>
            </div>
        </div>
        <div class="flex justify-content-center">
        </div>
        <div class="flex items-center align-items-center ml-4 ">
            <div class="border-left-2 pl-3" style="border-color: #9F88F2;">
                <p-overlayPanel #opNotification>
                    <ng-template pTemplate="content">
                        <span>Centro de Notificaciones</span>
                    </ng-template>
                </p-overlayPanel>
                <i (click)="opNotification.toggle($event)" 
                    pTooltip="Notificaciones"
                    tooltipPosition="bottom"
                    class="fa-solid fa-bell mr-4 p-text-secondary cursor-pointer" 
                    pBadge style="font-size: 1.6rem" value="0" severity="danger"></i>
                    
                <i (click)="opMesagge.toggle($event)" 
                    pTooltip="Mensajes"
                    tooltipPosition="bottom"
                    class="fa-solid fa-envelope p-text-secondary cursor-pointer" 
                    pBadge style="font-size: 1.6rem" value="{{listaMensajes.length}}" severity="danger"></i>

                    <p-overlayPanel #opMesagge>
                        <ng-template pTemplate="content">
                            <div class="border-bottom-1 pb-2 mb-2">Mis Mensajes</div>
                       
                            <a class="" *ngFor="let mensaje of listaMensajesPreview">
                                <div class="text-gray-300 p-2">
                                    <!-- <div class="small text-gray-500">{{mensaje.fechaCreacion}}</div> -->
                                    <span class="font-weight-bold">{{mensaje.texto}}</span>
                                </div>
                            </a>
                        </ng-template>
                    </p-overlayPanel>
            </div>
        </div>
    </div>

    <div  #topbarmenu  class="layout-topbar-menu" [ngClass]="{'layout-topbar-menu-mobile-active': layoutService.state.profileSidebarVisible}"> 
        <button #btn type="button" class="layout-topbar-button" pButton (click)="menu.toggle($event)">
            <i class="fa-solid fa-gear"></i>
        </button>
        <p-tieredMenu #menu [model]="items_perfil" [popup]="true"></p-tieredMenu>
    </div>
</div>