import { ElementRef, OnInit } from '@angular/core';
import { Component, Renderer2  } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { Area, AreaRequest, TipoAreaEnum } from '../../../model/area';
import { Departamento } from '../../../model/departamento';
import { AreaService } from '../../../service/area.service';
import { AuthService } from '../../../service/auth.service';
import { DepartamentoService } from '../../../service/departamento.service';
import { EntidadService } from '../../../service/entidad.service';
import { LayoutService } from '../../service/app.layout.service';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { PanelMenuModule } from 'primeng/panelmenu';
import { Button } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import { NgFor } from '@angular/common';

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html',
    styleUrls: ['./app.menu.component.css'],
    providers: [ConfirmationService, MessageService],
    standalone: true,
    imports: [NgFor, TooltipModule, RouterLink, Button, PanelMenuModule, ToastModule]
})
export class AppMenuComponent implements OnInit {

    departamentoArray: Departamento[] = []
    areasTrabajoArray: Area[] = []
    entidadesArray : any

    items: MenuItem[] = [];
    items_panel: MenuItem[] = [];

    constructor(public layoutService: LayoutService,
        private departamentoService: DepartamentoService,
        private entidadService : EntidadService,
        private areaService: AreaService,
        private authService: AuthService,
        public el: ElementRef,
        private router: Router,
        private renderer: Renderer2,
        private messageService: MessageService) { }

    ngOnInit() {
          this.items = [
            {
              tooltip: 'Inicio',
              icon: 'fa-solid fa-house',
              routerLink: '/app/inicio',
            },
            {
              icon: 'fa-solid fa-chart-line',
              routerLink: '/app/dashboard',
              tooltip: 'Dashboard',
            },
            {
              tooltip: 'Mi Equipo',
              icon: 'fa-solid fa-users',
              routerLink: '/app/usuario/mi-equipo',
            },
            {
              icon: 'fa-solid fa-bag-shopping',
              routerLink: ['/app/producto'],
              tooltip: 'Productos y Servicios',
            },
            {
              tooltip: 'Mis Entidades',
              icon: 'fa-solid fa-database',
              routerLink: ['/app/entidad'],
            },
            {
              tooltip: 'Contactos',
              icon: 'fa-solid fa-user',
              routerLink: ['/app/contacto'],
            },            
            {
              tooltip: 'Estados',
              icon: 'fa-solid fa-tag',
              routerLink: ['/app/estado'],
            },
            {
              tooltip: 'Papelera',
              icon: 'fa-solid fa-trash-can',
            }
        ];        

        this.areaService.areasArray$.subscribe(data=>{
          this.areasTrabajoArray = data
          //cargar menus     
          this.cargarMenu(this.areasTrabajoArray)
        })

        this.refreshDepartamentos()

    }

    toggleActiveClass(event: MouseEvent): void {
      const targetElement = event.target as HTMLElement;

      // Verifica si el elemento clicado tiene la clase p-menuitem-link
      if (targetElement.classList.contains('p-menuitem-link')) {
        // Alternar la clase 'active'

        // Eliminar la clase 'active' de todos los elementos con la clase p-menuitem-link
        const menuItems = document.querySelectorAll('.p-menuitem-link');
        menuItems.forEach(item => {
          this.renderer.removeClass(item, 'seleccionado');
        });

        this.renderer.addClass(targetElement, 'seleccionado')
      }else if( targetElement.classList.contains('p-menuitem-text') ){
        
        // Eliminar la clase 'active' de todos los elementos con la clase p-menuitem-link
        const menuItems = document.querySelectorAll('.p-menuitem-link');
        menuItems.forEach(item => {
          this.renderer.removeClass(item, 'seleccionado');
        });        

        const parentElement = targetElement.parentNode as HTMLElement;
        this.renderer.addClass(parentElement, 'seleccionado')
      }
    }    

    refreshDepartamentos() {
        this.departamentoArray = []
        this.areasTrabajoArray = []
        this.departamentoService.getByOrganizacionId(this.authService.getOrganizacionId()).subscribe(data => {
            this.departamentoArray = data
            data.forEach(d => {
                d.areaTrabajoList?.forEach(a => { this.areasTrabajoArray.push(a) })
            })

            //cargar menus     
            this.cargarMenu(this.areasTrabajoArray)
        })
    }

    getAllEntidades(){
        this.entidadService.getEntidadesByOrganizacionId(this.authService.getOrganizacionId()).subscribe(data=>{
          this.entidadesArray = data
        })
    }

    cargarMenu(areasArray: Area[]) {

        let areasPublicasItemsMenu: MenuItem[] = []
        let areasPrivadasItemsMenu: MenuItem[] = []
        areasArray.forEach(area => {

          if(area.tipo == TipoAreaEnum.PUBLICA)
            areasPublicasItemsMenu.push({
            icon: 'fa-solid fa-hashtag',
            label: area.nombre, 
            routerLink: ['area', area.id] 
          })
          else if(area.tipo == TipoAreaEnum.PRIVADA){
            if(area.usuarioPerfilId == this.authService.getPerfilActual().id)
              areasPrivadasItemsMenu.push({ label: area.nombre, routerLink: ['area', area.id] })
          }
            
        })

        this.items_panel = [
            {
                label: 'Areas de trabajo',
                expanded: true,
                items: [
                    ...areasPublicasItemsMenu,
                    {   
                        label: 'Nueva Area',
                        icon: 'pi pi-plus', 
                        command: ()=>{ this.nuevaAreaTrabajo(TipoAreaEnum.PUBLICA)}
                    }
                 ]

            },
            {
              label: 'Areas Privadas',
              expanded: true,
              items: [
                  ...areasPrivadasItemsMenu,
                  {   
                      label: 'Nueva Area Privada',
                      icon: 'pi pi-plus', 
                      command: ()=>{ this.nuevaAreaTrabajo(TipoAreaEnum.PRIVADA)}
                  }
               ]

          },            
        ];
    }


    nuevaAreaTrabajo(tipo:TipoAreaEnum){  
      let request: AreaRequest = {
        tipo:tipo, 
        organizacionId:this.authService.getOrganizacionId(),
        usuarioPerfilId: this.authService.getPerfilActual().id
      }     
      this.areaService.saveNewByOrganizacionId(request).subscribe(area=>{    
        this.refreshDepartamentos()      
        this.messageService.add({severity:'success', summary:'Mensaje', detail:  area.nombre + ' creada exitosamente'}); 
        this.router.navigate(['area', area.id])
      })    
    
    }       

}
