<div class="layout-wrapper" [ngClass]="containerClass">
    <app-topbar></app-topbar>
    <div class="layout-sidebar">
        <app-menu></app-menu>
    </div>
    <!-- <div class="layout-main-container"> -->
    <div>
        <!-- <div > -->
        <p-scrollPanel [styleClass]="'layout-main-container'">
            <router-outlet></router-outlet>
        </p-scrollPanel>
        <!-- </div> -->
        <!-- <app-footer></app-footer> -->
    </div>
    <!-- <app-config></app-config> -->
    <!-- <div class="layout-mask"></div> -->
</div>
