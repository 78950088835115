import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Departamento } from '../model/departamento';

@Injectable({
  providedIn: 'root'
})
export class DepartamentoService {

  departamentoArray :Departamento[] = []
  departamentoArray$ = new Subject<Departamento[]>()

  constructor(private http: HttpClient) { }

  getById(id:number) : Observable<Departamento>{

    if(id!=undefined){
      let url = environment.API_URL + "/departamento/"+id
      return this.http.get<Departamento>(url)
    }

    return new Observable<Departamento>()
  }

  getByOrganizacionId(id:number) : Observable<Departamento[]>{
    let url = environment.API_URL + "/departamento/organizacion/"+id
    return this.http.get<Departamento[]>(url)
  }

  save(departamento:Departamento){
    let url = environment.API_URL + "/departamento/save"

    this.http.post<Departamento>(url,departamento).subscribe(response=>{
      this.departamentoArray.push(response)
      this.departamentoArray$.next(this.departamentoArray)
    })
  }

  delete(departamento:Departamento){
    let url = environment.API_URL + "/departamento/delete"

    this.http.post<Departamento>(url,departamento).subscribe(response=>{
      this.departamentoArray$.next(this.departamentoArray)
    })
  }  
}
