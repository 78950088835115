export const environment = {
    production: false,
    API_URL: import.meta.env.NG_APP_API_URL,
    featureToggle: true,
    firebase: {
        apiKey: "AIzaSyB2l5AbV7oEPtp651VMtTlcreV1VFPHYVg",
        authDomain: "bixper-3b424.firebaseapp.com",
        databaseURL: "https://bixper-3b424.firebaseio.com",
        projectId: "bixper-3b424",
        storageBucket: "bixper-3b424.appspot.com",
        messagingSenderId: "549022898956",
        appId: "1:549022898956:web:563f6579e3473b86988729"
    }
};
