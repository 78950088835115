import { provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { ApplicationConfig, importProvidersFrom, LOCALE_ID } from "@angular/core";
import { provideFirebaseApp, initializeApp as initializeApp_alias } from "@angular/fire/app";
import { provideAuth, getAuth as getAuth_alias } from "@angular/fire/auth";
import { BrowserModule } from "@angular/platform-browser";
import { provideAnimations } from "@angular/platform-browser/animations";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { environment } from "src/environments/environment";
import { appRoutes } from "./app.routes";
import { provideRouter } from "@angular/router";
import { HashLocationStrategy, LocationStrategy } from "@angular/common";

export const appConfig: ApplicationConfig = {
    providers: [
        // importProvidersFrom(BrowserModule),
        provideRouter(appRoutes),
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        { provide: LOCALE_ID, useValue: 'es-CL' },
        provideAnimations(),
        provideHttpClient(
            withInterceptorsFromDi()), 
            provideFirebaseApp(() => initializeApp(environment.firebase)),
            provideAuth(() => getAuth()
        )
    ]
};