import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Columna } from 'src/app/model/Columna';
import { Entidad } from 'src/app/model/Entidad';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EntidadService {

  entidades : any = []
  entidades$ = new Subject<any>()
  
  columnas : Columna[] = []  
  columnas$ = new Subject<any>()

  constructor(private http: HttpClient) { }

  save(entidad:Entidad) : Observable<Entidad>{
    let url = environment.API_URL + "/entidad/save";    
    return this.http.post<Entidad>(url, entidad)
  }

  delete(entidad:Entidad){
    let url = environment.API_URL + "/entidad/delete"
    return this.http.post<any>(url,entidad)
  }

  getEntidades$(){    
    return this.entidades$.asObservable()    
  }
  getcolumnas$(){    
    return this.columnas$.asObservable()    
  }

  getEntidadById(id:number) : Observable<Entidad>{

    if(id!=undefined){
      let url = environment.API_URL + "/entidad/"+id        
      return this.http.get(url)
    }

    return new Observable<Entidad>()

  }

  getEntidadesByOrganizacionId(id:number) : Observable<Entidad[]>{
    let url = environment.API_URL + "/entidad/all/organizacion/"+id
    return this.http.get<Entidad[]>(url)
  }

  getColumnasByEntidadId(id?:number) : Observable<Columna[]>{
    let url = environment.API_URL + "/columna/entidad/"+id        
    return this.http.get<Columna[]>(url)
  }


  savecolumna(columna : Columna){
    let url = environment.API_URL + "/columna/save"
    return this.http.post<any>(url, columna)
  }

  saveColumnas(columnas : Columna[]){
    let url = environment.API_URL + "/columna/save/list"

    this.http.post<any>(url, columnas).subscribe(data =>{
      
    });     
  }

  deleteColumna(columna : Columna){
    let url = environment.API_URL + "/columna/delete"
    return this.http.post<any>(url, columna)
  }

}
