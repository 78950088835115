import { Component, OnInit } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { registerLocaleData } from '@angular/common';
import localeEsCL from '@angular/common/locales/es-CL';
import { Title } from '@angular/platform-browser';
import { AuthService } from './service/auth.service';
import { RouterOutlet } from '@angular/router';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [RouterOutlet]
})
export class AppComponent implements OnInit {

  constructor(private primengConfig: PrimeNGConfig,
    private title: Title,
    private authService: AuthService){}

  ngOnInit() {

    registerLocaleData(localeEsCL)
    
    if(this.authService.getOrganizacion()!=undefined){
      this.title.setTitle(this.authService.getOrganizacion().nombre!)
    }


    this.primengConfig.zIndex = {
        modal: 1100,    // dialog, sidebar
        overlay: 1000,  // dropdown, overlaypanel
        menu: 1000,     // overlay menus
        tooltip: 1100,   // tooltip
    };

    this.primengConfig.setTranslation({
      accept: 'Confirmar',
      reject: 'Cancelar',      
      monthNames : [ "Enero","Febrero","Marzo","Abril","Mayo","Junio", "Julio","Agosto","September","Octubre","Noviembre","Diciembre" ],
      monthNamesShort: [ "Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic" ],
      dayNames: [ "Domingo", "Lunes", "Martes", "Miercoles", "Jueves", "Viernes", "Sabado" ],
      dayNamesShort: [ "Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab" ],
      dayNamesMin: [ "Do","Lu","Ma","Mi","Ju","Vi","Sa" ],
      emptyMessage: 'Sin Datos',
      today: 'Hoy',
      clear: 'Limpiar',
      startsWith: 'Comienza Con',
      contains: "Contiene",
      notContains: "No Contiene",
      endsWith: "Termina Con",
      equals: "Es Igual",
      notEquals: "No es Igual",
      noFilter: "Sin Filtro",      
    });     


    this.primengConfig.ripple = true    
  }
}
