<div class="flex h-full">
    <div class="flex p-0 sidebar-menu">
        <ul class="p-0">
            <li *ngFor="let item of items"
                pTooltip="{{ item.tooltip }}" 
                tooltipPosition="right"
                [routerLink]="item.routerLink">
                <p-button styleClass="p-button-sidebar">
                    <i class="{{ item.icon }} "></i>
                </p-button >
            </li>
        </ul>
    </div>
    <div class="flex sidebar-panel">
        <p-panelMenu [model]="items_panel" [style]="{'width':'180px'}" [multiple]="true" (click)="toggleActiveClass($event)"></p-panelMenu>
    </div>
</div>

<p-toast></p-toast>
