import { Formulario } from "./formulario"
import { Grupo } from "./grupo"

export interface Area {
    id?:number
    nombre?:string
    descripcion?:string
    departamentoId?:number
    tipo?:TipoAreaEnum    
    gruposList?:Grupo[]
    usuarioPerfilId?:number

    //transient
    seleccion?:boolean
    completed?:boolean
    color?:string
    show?:boolean
    showInput?:boolean
    formulariosArray?:Formulario[]
}


export enum TipoAreaEnum{
    PUBLICA="PUBLICA", PRIVADA="PRIVADA"
}

export interface AreaRequest {
    organizacionId?:number
    tipo?:TipoAreaEnum
    usuarioPerfilId?:number
}