import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Area, AreaRequest } from 'src/app/model/area';
import { DepartamentoAreaNative } from 'src/app/model/departamentoAreaNative';
import { environment } from 'src/environments/environment';
import { DepartamentoService } from './departamento.service';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AreaService {

  areasArray : Area[] = []
  areasArray$ = new Subject<Area[]>()
  areaSeleccionada$ = new Subject<Area>()

  constructor(private http: HttpClient, 
    private departamentoService : DepartamentoService,
    private authService: AuthService) { }

  save(area : Area) : Observable<any>{
    let url = environment.API_URL + "/area/save"
    return this.http.post<any>(url, area)
  }

  saveNewByOrganizacionId(request : AreaRequest) : Observable<any>{
    let url = environment.API_URL + "/area/save/organizacion"
    return this.http.post<any>(url, request)
  }

  getCountValidacionByAreaId(areaId:number) : Observable<number>{
    let url = environment.API_URL + '/area/'+areaId+'/count'
    return this.http.get<number>(url)
  }    

  getAreasByOrganizacionAndDepNative(organizacionId:number) : Observable<DepartamentoAreaNative[]>{
    let url = environment.API_URL + "/area/organizacion/"+organizacionId
    return this.http.get<DepartamentoAreaNative[]>(url)
  }  

  getAreasByOrganizacionAndAreaIdDepAndGrupoNative(organizacionId:number, areaId:number) : Observable<DepartamentoAreaNative[]>{
    let url = environment.API_URL + "/area/depto/grupo/organizacion/"+organizacionId
    return this.http.get<DepartamentoAreaNative[]>(url)
  }

  getAreasByOrganizacionAndDepAndGrupoNative(organizacionId:number) : Observable<DepartamentoAreaNative[]>{
    let url = environment.API_URL + "/area/depto/grupo/organizacion/"+organizacionId
    return this.http.get<DepartamentoAreaNative[]>(url)
  }    

  getAreasByUsuarioAndOrganizacion(usuarioId:number, organizacionId:number) : Observable<Area[]>{
    let url = environment.API_URL + "/area/usuario/"+usuarioId+"/organizacion/"+organizacionId
    return this.http.get<Area[]>(url)
  }

  getAreasByDepartamentoId(id:number) : Observable<Area[]>{
    let url = environment.API_URL + "/area/departamento/"+id
    return this.http.get<Area[]>(url)
  }

  getById(id:number) : Observable<Area>{


    if(id!=undefined){
      let url = environment.API_URL + "/area/"+id
      return this.http.get<Area>(url)
    }

    return new Observable<Area>()
  }  

  deleteAll(area : Area){
    let url = environment.API_URL + "/area/delete/all"
    return this.http.post<any>(url, area)
  }

  refreshAreaTrabajoGlobal(){
    let areaArray : Area[] = []
    this.departamentoService.getByOrganizacionId(this.authService.getOrganizacionId()).subscribe(data => {      
      data.forEach(d => {
        d.areaTrabajoList?.forEach(a => { areaArray.push(a) })            
      })
      this.areasArray$.next(areaArray)
    })        
  }

}
