import {Routes } from '@angular/router';
import { AppLayoutComponent } from './layout/app.layout.component';

export const appRoutes: Routes = [
  {
    path: 'app', component: AppLayoutComponent,    
    children: [
      { path: 'inicio'   , loadChildren: () => import('./modules/inicio/routes').then(r => r.routes) },      
      { path: 'dashboard', loadChildren: () => import('./modules/dashboard/routes').then(r => r.routes) },
      { path: 'area'     , loadChildren: () => import('./modules/area/routes').then(r => r.routes) },
      { path: 'usuario'  , loadChildren: () => import('./modules/usuario/routes').then(r => r.routes) },
      { path: 'producto' , loadChildren: () => import('./modules/producto/routes').then(r => r.routes) },
      { path: 'entidad'  , loadChildren: () => import('./modules/entidad/routes').then(r => r.routes) },
      { path: 'instancia', loadChildren: () => import('./modules/instancia/routes').then(r => r.routes) },
      { path: 'contacto' , loadChildren: () => import('./modules/contacto/routes').then(r => r.routes) },
      { path: 'estado'   , loadChildren: () => import('./modules/estado/routes').then(r => r.routes) },      
    ]
  },
  { path: '', loadChildren: () => import('./modules/auth/routes').then(r => r.routes) },
  { path: 'landing'  , loadChildren: () => import('./modules/landing/routes').then(r => r.routes) },
  { path: 'formulario'   , loadChildren: () => import('./modules/formulario/routes').then(r => r.routes) },
];
