import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Mensajes } from 'src/app/model/mensajes';

@Injectable({
  providedIn: 'root'
})
export class MensajesService {

  listaMensajesByPerfil$ = new Subject<Mensajes[]>()

  constructor(private http: HttpClient) { }

  create(mensaje:Mensajes) : Observable<Mensajes>{
    let url = environment.API_URL + "/mensajes/create"
    return this.http.post<Mensajes>(url, mensaje)
  }  

  save(mensaje:Mensajes) : Observable<Mensajes>{
    let url = environment.API_URL + "/mensajes/save"
    return this.http.get<Mensajes>(url)
  }  

  delete(mensaje:Mensajes) : Observable<void>{
    let url = environment.API_URL + "/mensajes/delete"
    return this.http.post<void>(url, mensaje)
  }    

  getByTareaId(tareaId:number) : Observable<Mensajes[]>{
    let url = environment.API_URL + "/mensajes/tarea/" + tareaId

    if(tareaId!=undefined)
      return this.http.get<Mensajes[]>(url)

    return new Observable<Mensajes[]>
  }    

  getByPerfil(perfilId:number) : Observable<Mensajes[]>{
    let url = environment.API_URL + "/mensajes/perfil/" + perfilId
    return this.http.get<Mensajes[]>(url)
  }   

  getByPerfilDestino(perfilIdDestino:number) : Observable<Mensajes[]>{
    let url = environment.API_URL + "/mensajes/perfil/destino/" + perfilIdDestino
    return this.http.get<Mensajes[]>(url)
  }    

}
