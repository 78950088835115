import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MenuItem, PrimeTemplate } from 'primeng/api';
import { LayoutService } from '../../service/app.layout.service';
import { AuthService } from '../../../service/auth.service';
import { Mensajes } from '../../../model/mensajes';
import { Usuario } from '../../../model/usuario';
import { UsuarioPerfiles } from '../../../model/usuarioPerfiles';
import { MensajesService } from '../../../service/mensajes.service';
import { Router } from '@angular/router';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { ButtonDirective } from 'primeng/button';
import { BadgeModule } from 'primeng/badge';
import { TooltipModule } from 'primeng/tooltip';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { NgIf, NgFor, NgClass } from '@angular/common';
import { AvatarModule } from 'primeng/avatar';

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html',
    standalone: true,
    imports: [AvatarModule, NgIf, OverlayPanelModule, PrimeTemplate, TooltipModule, BadgeModule, NgFor, NgClass, ButtonDirective, TieredMenuModule]
})
export class AppTopBarComponent implements OnInit {

    @ViewChild('menubutton') menuButton!: ElementRef;
    @ViewChild('topbarmenubutton') topbarMenuButton!: ElementRef;
    @ViewChild('topbarmenu') menu!: ElementRef;

    nombreOrganizacion?: string
    listaMensajes: Mensajes[] = []
    listaMensajesPreview: Mensajes[] = []
    usuario: Usuario = {}
    perfilActual: UsuarioPerfiles = {}
    items_perfil: MenuItem[] = []
    isTienePerfiles : boolean = false

    constructor(
        private mensajesService: MensajesService,
        private router: Router,
        public layoutService: LayoutService,
        private authService: AuthService) { }


    ngOnInit(): void {

        this.usuario = this.authService.getUsuario()
        this.perfilActual = this.usuario.perfilActual!
        this.isTienePerfiles = this.usuario.perfilList!.length > 1

        this.mensajesService.listaMensajesByPerfil$.subscribe((data) => {
            this.listaMensajes = data
        })

        this.refreshMensajes();

        this.nombreOrganizacion = this.authService.getOrganizacion().nombre

        this.items_perfil = [
            {
                label: 'Mi perfil',
                icon: 'fa-solid fa-user',
            },
            {
                 label: 'Cambiar Perfil',
                 icon: 'pi pi-fw pi-users',
                 command: ()=>{ this.cambiarPerfilClick() }, visible: this.isTienePerfiles

            },            
            {
                separator: true
            },
            {
                label: 'Cerrar sesion',
                icon: 'fa-solid fa-power-off',
                command: () => { this.cerrarSesion() }
            }
        ];
    }

    refreshMensajes() {
        this.mensajesService.getByPerfil(this.perfilActual.id!).subscribe(data => {
            this.listaMensajes = data

            let index = 0
            this.listaMensajes.forEach(d => {

                if (index < 5)
                    this.listaMensajesPreview.push(d)

                index++
            })
        })
    }

    cerrarSesion() {
        this.router.navigate(['/']);
    }

    cambiarPerfilClick(){
        this.router.navigate(['/seleccion-perfil'])        
    }
}
